<template>
  <div class="card p-3 templates-list">
    <template v-if="templates.length > 0">
      <draggable
        :animation="1000"
        :fallback-tolerance="1"
        :list="templates"
        :scroll-sensitivity="200"
        class="list-group"
        ghost-class="ghost"
        group="templates"
        handle=".drag-icon"
        @change="templateSortLog"
      >
        <div v-for="template in templates" :key="template.id"
             :class="{'active': !!value && (template.id === value.id) }"
             class="app-border p-3 position-relative mb-3 active-element"
             @click="changeTemplate(template)">
          <div class="d-flex">
            <div class="drag-icon mr-1">
              <el-tooltip content="Drag & Drop" effect="dark" placement="bottom-start">
                <DragVerticalIcon></DragVerticalIcon>
              </el-tooltip>
            </div>
            <div class="content-container">
              <div class="mb-2 name"><b>{{ template.name }}</b></div>
              <div class="font-400 gray-600-color pre-md-text">{{ template.message }}</div>
              <div class="close-btn" @click="tryRemoveTemplate(template.id)"><img :src="closeImg"/></div>
            </div>
          </div>
        </div>
      </draggable>
    </template>
    <template v-else>
      <div class="no-templates p-3">
        Fill out the fields and click Save to create your first template
      </div>
    </template>
    <confirmation-modal
      ref="deletionConfirmationModal"
      cancelBtnText="Cancel"
      confirmBtnText="Delete"
      header="Delete template?"
      question="Are you sure you want to delete this template?"
      @on-accept="removeTemplate"
    />
  </div>
</template>
<script>
import ConfirmationModal from '@/components/App/modals/ConfirmationModal.vue';
import {mapActions} from 'vuex';
import {
  DELETE_HM_TEMPLATE_REQUEST,
  DELETE_TEAM_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_REQUEST,
  UPDATE_TEMPLATE_ORDER_REQUEST,
} from '@/store/storeActions';
import draggable from 'vuedraggable';
import DragVerticalIcon from 'vue-material-design-icons/DragVertical.vue';

export default {
  components: {
    ConfirmationModal,
    draggable,
    DragVerticalIcon,
  },
  data: function() {
    return {
      closeImg: 'img/tools/close.svg',
    };
  },
  props: {
    templates: Array,
    value: Object,
    formBlocked: Boolean,
    unsavedFormData: Object,
    isTeamTemplate: Boolean,
    isHiringManagerTemplate: Boolean,
  },
  methods: {
    ...mapActions([
      DELETE_TEMPLATE_REQUEST,
      DELETE_TEAM_TEMPLATE_REQUEST,
      DELETE_HM_TEMPLATE_REQUEST,
      UPDATE_TEMPLATE_ORDER_REQUEST,
    ]),
    tryRemoveTemplate: function(id) {
      if (this.$refs.deletionConfirmationModal) {
        this.$refs.deletionConfirmationModal.openModal(id);
      }
    },
    removeTemplate: function(id) {
      if (this.isTeamTemplate) {
        this.DELETE_TEAM_TEMPLATE_REQUEST({id});
      } else if (this.isHiringManagerTemplate) {
        this.DELETE_HM_TEMPLATE_REQUEST({id});
      } else {
        this.DELETE_TEMPLATE_REQUEST({id});
      }
    },
    changeTemplate(template) {
      if (this.formBlocked) {
        this.$emit('save-template-confirmation', {data: {...this.unsavedFormData}, template});
      } else {
        this.$emit('input', template);
      }
    },
    templateSortLog() {
      const sortedIds = this.templates.map((template) => template.id);
      let key = "templateSortOrder";
      if (this.isHiringManagerTemplate) key = "hiringManagerTemplateSortOrder";
      else if (this.isTeamTemplate) key = "teamTemplateSortOrder";
      const payload = { [key]: sortedIds };
      this.UPDATE_TEMPLATE_ORDER_REQUEST({payload});
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/argon.scss';

.templates-list {
  max-height: 80vh;
  overflow-y: auto;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.no-templates {
  border: 3px dashed $gray-300;
  background: $gray-100;
  color: $gray-500;
  box-sizing: border-box;
  border-radius: 2px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 1.2px;
}

.active {
  border-color: $primary;

  .name {
    color: $primary;
  }
}

.ghost {
  opacity: 0;
  background: #c8ebfb;
}

.drag-icon {
  cursor: grab;
}
</style>
